import React from 'react';
import Layout from '../components/Layout';
import Scroll from '../components/Scroll';
const AboutPage = () => (
  <Layout>
    <article id="main">
      <header>
        <h2>Why choose us?</h2>
        <p>
          Because we are exactly the kind of team who aims to be the team we
          would want to work with
        </p>
        <p><a href="tel:7602590211"> Call Now 760-259-0211</a></p>
      </header>
      <section className="wrapper style5">
        <div className="inner">
          <section>
            <header>
              <h4>Carlsbad Movers has a strict employment processe</h4>
              <p>
                All of our employees have passed LiveScan and random drug
                screening. The movers who will be performing your move have
                passed a rigorous fitness test, and have undergone our in house
                training process for packing, and loading so that every single
                move is performed up to our standards.
              </p>
            </header>
            <header>
              <h4>Satisfaction is our reputation</h4>
              <p>
                We believe in word of mouth business, and to ensure that you
                will refer your friends and family to work with us we do
                everything in our power to guarantee your satisfaction.
              </p>
            </header>
          </section>
        </div>
      </section>


    </article>
  </Layout>
);
export default AboutPage;
